exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-activities-tsx": () => import("./../../../src/pages/activities.tsx" /* webpackChunkName: "component---src-pages-activities-tsx" */),
  "component---src-pages-badge-tsx": () => import("./../../../src/pages/badge.tsx" /* webpackChunkName: "component---src-pages-badge-tsx" */),
  "component---src-pages-code-of-conduct-tsx": () => import("./../../../src/pages/code-of-conduct.tsx" /* webpackChunkName: "component---src-pages-code-of-conduct-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-templates-activity-tsx": () => import("./../../../src/templates/activity.tsx" /* webpackChunkName: "component---src-templates-activity-tsx" */),
  "component---src-templates-talk-tsx": () => import("./../../../src/templates/talk.tsx" /* webpackChunkName: "component---src-templates-talk-tsx" */)
}

